// components
// import SvgIconStyle from '../../../../components/SvgIconStyle';
import Iconify from '../../../../components/Iconify';
// hooks
import useAuth from '../../../../hooks/useAuth';

// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

// const ICONS = {
//   user: getIcon('ic_user'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
// };

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Dashboard', path: '/admin/dashboard', icon: <Iconify icon={'bx:bxs-dashboard'} width={32} height={32} /> },
      { title: 'Org Chart', path: '/admin/org-chart', icon: <Iconify icon={'icomoon-free:tree'} width={32} height={32} /> },
      { title: 'Employees', path: '/admin/employees', icon: <Iconify icon={'prime:users'} width={32} height={32} /> },
      {
        title: 'Performance',
        path: '/admin/performance',
        icon: <Iconify icon={'charm:rocket'} width={32} height={32} />,
        children: [
          { title: 'Goals', path: '/admin/performance/goals', icon: <Iconify icon={'fluent:target-arrow-20-filled'} width={32} height={32} /> },
          { title: 'Reviews', path: '/admin/performance/reviews' },
          { title: 'Surveys', path: '/admin/performance/surveys' },
        ],
      },
      {
        title: 'Learning',
        path: '/admin/learning',
        icon: <Iconify icon={'si-glyph:easal'} width={32} height={32} />,
        children: [
          { title: 'Training Courses', path: '/admin/learning', icon: <Iconify icon={'si-glyph:easal'} width={32} height={32} /> },
          { title: 'Training Tracker', path: '/admin/tracker', icon: <Iconify icon={'si-glyph:easal'} width={32} height={32} /> },
        ],
      },
      {
        title: 'Docs',
        path: '/admin/library',
        icon: <Iconify icon={'iconoir:multiple-pages'} width={32} height={32} />,
        children: [
          { title: 'HR', path: '/admin/docs-hr', icon: <Iconify icon={'si-glyph:easal'} width={32} height={32} /> },
          { title: 'Safety', path: '/admin/docs-safety', icon: <Iconify icon={'si-glyph:easal'} width={32} height={32} /> },
          { title: 'Employee', path: '/admin/docs-employee', icon: <Iconify icon={'si-glyph:easal'} width={32} height={32} /> },
          { title: 'Pending Signature', path: '/admin/docs-pending', icon: <Iconify icon={'si-glyph:easal'} width={32} height={32} /> },
        ],
      },
      { title: 'Time Off', path: '/admin/time-off', icon: <Iconify icon={'ant-design:user-outlined'} width={32} height={32} /> },
    ],
  },

  // MANAGEMENT - separate section in the side nav
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four' },
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  // },
];

export default function NavConfig(){
  const { user } = useAuth();

  // remove time-off section depending on app settings value
  if(user !== null && user.app_settings && user.app_settings.length > 0){
    const timeOffAppConfig = user.app_settings.find((app: any) => app.type === 'time_off');

    if(timeOffAppConfig && !timeOffAppConfig.is_enabled){
      let tmpNavConfig = [...navConfig];
      tmpNavConfig[0].items = tmpNavConfig[0].items.filter((item: any) => item.title !== 'Time Off');

      return tmpNavConfig;
    }
  }

  // return default nav config
  return navConfig;
}

// export default navConfig;

import {TrainingCourseType} from "../../../@types/user";
import {CourseCategory} from "../../../@types/training";

export const sortCoursesByCategory = (courses: TrainingCourseType[]) => {
    let foundCategories: string[] = [];
    let coursesByCategory: CourseCategory[] = [];

    courses.forEach((course) => {
        if(!foundCategories.includes(course.category_id)) {
            foundCategories.push(course.category_id);

            let newCategory: CourseCategory = {
                id: course.category_id,
                label: course.category_label,
                courses: [course]
            };

            coursesByCategory.push(newCategory);
        }else{
            let index = coursesByCategory.findIndex(item => item.id === course.category_id);
            coursesByCategory[index].courses.push(course);
        }
    });

    coursesByCategory = coursesByCategory.sort((a, b) => {
        let labelA = a.label.toUpperCase();
        let labelB = b.label.toUpperCase();
        return (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0;
    });

    return coursesByCategory;
};

import { useState, useCallback, useEffect, useMemo } from 'react';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import axios from 'src/utils/axios';
import debounce from 'lodash/debounce';
// @mui
import {
    Box, Container, Stack, CircularProgress,
    Grid, Typography, Button, InputAdornment
} from '@mui/material';
// hooks
import useSettings from '../../../hooks/useSettings';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Page from '../../../components/Page';
import { PageHeader } from '../../../sections/general/pages';
import Iconify from '../../../components/Iconify';
// sections
import {
    DocumentCategoryTabs,
    DocumentsTable,
} from '../../../sections/admin/document';
// types
import { DocumentType, DocumentSubcategoryType, DocumentCategoryType } from '../../../@types/user';
// config
import { DocumentParentTypeEnum } from '../../../config';
import InputStyle from 'src/components/InputStyle';

// ----------------------------------------------------------------------

const initialCategory: DocumentCategoryType = {
    id: '',
    name: '',
    documents: [],
    subcategories: [],
    status: '',
    created_by: '',
    created_at: '',
    updated_at: '',
}

const initialCategories: DocumentCategoryType[] = [];

// ----------------------------------------------------------------------

export default function DocsEmployeePage() {
    const isMountedRef = useIsMountedRef();
    const { themeStretch } = useSettings();
    const [isLoading, setIsLoading] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get('tab') || '';
    const [curTab, setCurTab] = useState( '');
    const [categories, setCategories] = useState(initialCategories);
    const [documents, setDocuments] = useState<DocumentType[]>([]);
    const [curCategory, setCurCategory] = useState<DocumentCategoryType>(initialCategory);
    const [curSubcategory, setCurSubcategory] = useState<DocumentSubcategoryType | null>(null);
    const [searchTerm, setsearchTerm] = useState('');
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentType[]>([]);
    const navigate = useNavigate();
    const getCategorizedDocuments = useCallback(async () => {
        try {
            const url = `/app/admin/document/all/categorized`;

            setIsLoading(true);

            const response = await axios.get(url);

            setIsLoading(false);

            if (isMountedRef.current) {
                let { data } = response.data;

                setCategories(data.filter( (item: any) => item.name === 'Employee'));

                if (data.length > 0) {
                    setCurCategory(data[0]);
                    setCurTab(data[0].id.toString());
                }
            }
        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    }, [isMountedRef]);

    const getAllDocuments = useCallback(async () => {
        try {
            let url = `/app/admin/document/all`;

            const response = await axios.get(url, {
                params: {}
            });

            if (isMountedRef.current) {
                let { data } = response;

                setDocuments(data.documents);
            }
        } catch (err) {
            console.log(err);
        }
    }, [isMountedRef]);

    const filterDocuments = useCallback(async (searchTerm: string) => {
        setIsLoading(true);

        // wait to prevent page flickering for fast typers
        await new Promise((resolve) => setTimeout(resolve, 500));

        if (searchTerm) {
            let filtered = documents.filter((item: DocumentType) => {
                let name = item.name.toLowerCase();
                return name.includes(searchTerm.toLowerCase());
            });

            setFilteredDocuments(filtered);
        }

        setIsLoading(false);
    }, [setIsLoading, documents, setFilteredDocuments]);

    useEffect(() => {
        getCategorizedDocuments();
        getAllDocuments();
    }, [getCategorizedDocuments, getAllDocuments]);

    useEffect(() => {
        if (categories.length > 0) {
            if(curCategory.id !== ''){
                // @ts-ignore
                const newCurCategory = categories.find(item => item.id === curCategory.id);
                if(newCurCategory === undefined){
                    setCurCategory(categories[0]);
                    setCurTab(categories[0].id.toString());
                }else{
                    setCurCategory(newCurCategory);
                }
            }
            // setCurCategory(categories[0]);
            // setCurTab(categories[0].id.toString());
        }
    }, [categories, setCurCategory, setCurTab, curCategory.id]);

    useEffect(() => {
        filterDocuments(searchTerm);
    }, [searchTerm, filterDocuments]);

    const onDeleteDocument = useCallback((doc: DocumentType) => {
        switch (doc.type) {
            case DocumentParentTypeEnum.category:
                // find the category index
                const categoryIndex = categories.findIndex(item => item.id === doc.type_id);

                if (categoryIndex > -1) {
                    const newCategories = [...categories];
                    const newCategory = { ...categories[categoryIndex] };
                    newCategory.documents = newCategory.documents.filter(item => item.id !== doc.id);
                    newCategories[categoryIndex] = newCategory;
                    setCategories(newCategories);
                }
                break;
            case DocumentParentTypeEnum.subcategory:
                // find the subcategory
                let subcatIndex = -1;

                categories.forEach(category => {
                    if (subcatIndex === -1) {
                        subcatIndex = category.subcategories.findIndex(item => item.id === doc.type_id);
                    }
                });

                if(subcatIndex === -1){
                    const newCategories = [...categories];
                    const newCategory = { ...categories[subcatIndex] };
                    const newSubcategories = [ ...newCategory.subcategories];
                    const newSubcategory = { ...newSubcategories[subcatIndex] };
                    newSubcategory.documents = newSubcategory.documents.filter(item => item.id !== doc.id);
                    newSubcategories[subcatIndex] = newSubcategory;
                    newCategory.subcategories = newSubcategories;
                    newCategories[subcatIndex] = newCategory;
                    setCategories(newCategories);
                }
                break;
            default:
                console.log('Unknown document parent type: ', doc.type);
                break;
        }
    }, [categories, setCategories]);

    const location = useLocation();

    useEffect(() => {
        if (queryParams.get('tab')) {
            const tabId = queryParams.get('tab') === 'pending' ? '99999' : queryParams.get('tab') || '';
            changeSection(tabId);
        }
    }, [location, queryParams]);


    const changeSection = (tabId: string) => {
        setCurTab(tabId);
        const category = categories.find(item => item.id.toString() === tabId.toString());
        if (category) onSelectCategory(category);
    }

    const onTabClick = (tabId: string) => {
        navigate(0);
    }

    const onSelectCategory = (category: DocumentCategoryType) => {
        setCurCategory(category);
        setCurSubcategory(null);
    }

    const onSelectSubcategory = (subcategory: DocumentSubcategoryType) => {
        setCurSubcategory(subcategory);
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { value } = e.target;

        setsearchTerm(value);
    }

    const debouncedChangeHandler = useMemo(() => debounce(handleOnChange, 1000),[]);

    return (
        <Page title="Library">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
                    <PageHeader
                        title="Library"
                        subtitle="A hub for transparency"
                        caption="Sign forms, reference documents and access to your files here."
                    />
                </Stack>

                <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        {/*{(!isLoading &&*/}
                        {/*    <Box>*/}
                        {/*        <DocumentCategoryTabs curTab={curTab} categories={categories} onTabClick={onTabClick} />*/}
                        {/*    </Box>*/}
                        {/*)}*/}
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Box sx={{ textAlign: 'right' }}>
                            {!isLoading && (
                                <Button
                                    variant="contained"
                                    sx={{  alignSelf: 'flex-start' }}
                                    startIcon={<Iconify icon={'akar-icons:plus'} />}
                                    component={RouterLink}
                                    to={`/admin/custom-document/create`}
                                >
                                    Add a Document
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <Box sx={{ flexGrow: 1 }} />
                </div>

                <Box>
                    <Typography variant="subtitle1" mb={1}>
                        Filter/Search
                    </Typography>

                    <Grid container spacing={2}>

                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                            <InputStyle
                                fullWidth
                                onChange={debouncedChangeHandler}
                                placeholder="Search..."
                                label="Search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Iconify
                                                icon={'eva:search-fill'}
                                                sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <Box sx={{ flexGrow: 1 }} />
                </div>

                {isLoading && (
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}

                {/* {(!isLoading && resourceType === 'subcats') && (
          <DocumentSubcategoriesTable subcategories={subcategories} />
        )} */}

                {!isLoading && !searchTerm && (
                    <DocumentsTable
                        category={curCategory}
                        subcategory={curSubcategory}
                        onSelectCategory={onTabClick}
                        onSelectSubcategory={onSelectSubcategory}
                        onDelete={onDeleteDocument}
                    />
                )}

                {!isLoading && searchTerm && (
                    <DocumentsTable
                        category={curCategory}
                        subcategory={curSubcategory}
                        filteredDocuments={filteredDocuments}
                        onSelectCategory={onTabClick}
                        onSelectSubcategory={onSelectSubcategory}
                        onDelete={onDeleteDocument}
                    />
                )}
            </Container>
        </Page>
    );
}
/**
 * Injects TawkTo plugin into react DOM
 *
 * @param string propertyId
 * @param string widgetId
 * are available in TawkTo dashboard
 *
 * @param string zIndex
 * Add style to the widget. Only zIndex is currently supported
 */
class TawkTo {
    constructor(propertyId, widgetId , zIndex = null) {

        this._Tawk = this.init(propertyId, widgetId, zIndex);
    }

    init(propertyId, widgetId, zIndex) {
        if (!window) {
            throw new Error("Unvailable DOM");
        }

        let id = "_t_a_w_k";
        if (document.getElementById(id) === null) {
            // Prevent TawkTo from creating more instances if it already exists
            const element = document.createElement("script");
            element.id = id;
            element.async = true;
            element.src = "https://embed.tawk.to/" + propertyId + "/" + widgetId;
            element.char = "UTF-8";
            element.setAttribute("crossorigin", "*");

            const node = document.getElementsByTagName("script")[0];
            if (!node || !node.parentNode) {
                throw new Error("Unavailable DOM");
            }

            node.parentNode.insertBefore(element, node);
        }

        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_LoadStart = new Date();

        var tawk = window.Tawk_API;

        if (zIndex) {
            tawk.customStyle = { zIndex: zIndex };
        }

        return tawk;
    }

    /**
     * Callback function invoked right after the widget is rendered.
     * @returns void
     */
    onLoad(callback) {
        this._Tawk.onLoad = () => callback();
    }

    /**
     * Maximizes the chat widget.
     * @returns void
     */
    maximize() {
        if (this._Tawk.maximize !== undefined) {
            this._Tawk.maximize();
        }
    }

    /**
     * Minimises the chat widget.
     * @returns void
     */
    minimize() {
        if (this._Tawk.minimize !== undefined) {
            this._Tawk.minimize();
        }
    }

    /**
     * Minimizes or Maximizes the chat widget based on the current state.
     * @returns void
     */
    toggle() {
        if (this._Tawk.toggle !== undefined) {
            this._Tawk.toggle();
        }
    }

    /**
     * Opens the chat widget as a pop out.
     * @returns void
     */
    popup() {
        if (this._Tawk.popup !== undefined) {
            this._Tawk.popup();
        }
    }

    /**
     * Returns the current page status (online, away or offline).
     * @returns {string}
     */
    getStatus() {
        return this._Tawk.getStatus === undefined ? false : this._Tawk.getStatus();
    }

    /**
     * Set custom metadata regarding this chat/visitor.
     * @returns void
     * @param attribute
     * @param callback
     */
    setAttributes(attribute, callback) {
        if (this._Tawk.setAttributes !== undefined) {
            this._Tawk.setAttributes(attribute, callback);
        }
    }
}

export default TawkTo;

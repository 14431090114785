import React, { lazy } from 'react';
import { Navigate } from "react-router-dom";
import Loadable from "./loadable";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

const NotFound = Loadable(lazy(() => import('../pages/Page404')));

const ErrorRoutes = [
    {
        path: '*',
        element: <LogoOnlyLayout />,
        children: [
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" replace /> },
        ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
];

export default ErrorRoutes;

import { useState, useCallback, useEffect } from 'react';
import axios from 'src/utils/axios';
// @mui
import {
    Container, Stack, Box, CircularProgress, Typography,
    Hidden
} from '@mui/material';
// @types
// components
import Page from '../../../components/Page';
import BackButton from '../../../components/BackButton';
// sections
import { PageHeader } from '../../../sections/general/pages';
// import { LearningCategoryTabs  } from '../../../sections/employee/learning';
import { LearningCourseScrollMenu } from '../../../sections/admin/learning';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useSettings from '../../../hooks/useSettings';
import {sortCoursesByCategory} from "../utils/coursesUtil";
import {CourseCategory} from "../../../@types/training";

// ----------------------------------------------------------------------


const initialCategorizedCourses: CourseCategory[] = [];

// ----------------------------------------------------------------------

export default function EmployeeCompletedCourses() {
    const isMountedRef = useIsMountedRef();
    const { themeStretch } = useSettings();
    const [isLoading, setIsLoading] = useState(true);
    const [categorizedCourses, setCategorizedCourses] = useState(initialCategorizedCourses);

    const getTrainingCourses = useCallback(async () => {
        try {

            let url = `/app/employee/trainings`;

            setIsLoading(true);

            const response = await axios.get(url, {
                params: {
                    // ...
                }
            });

            setIsLoading(false);

            if (isMountedRef.current) {
                let { data } = response.data;

                let coursesByCategory = sortCoursesByCategory(data);
                setCategorizedCourses(
                    coursesByCategory.filter(
                        category => category.courses.some(course => course.user_did_complete)
                    )
                );
            }
        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    }, [isMountedRef]);

    useEffect(() => {
        getTrainingCourses();
    }, [getTrainingCourses]);

    return (
        <Page title="Learning">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Hidden only={['md', 'lg', 'xl']}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <BackButton />
                    </Stack>
                </Hidden>

                <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
                    <PageHeader
                        title="Completed Courses"
                        subtitle="Accomplish biggerss things"
                        caption="We create success together by providing awareness of new skills and opportunities to grow."
                    />
                </Stack>

                {/* Removed 2022.12.28 */}
                {/* <Box>
          <LearningCategoryTabs tab="training" />
        </Box> */}

                {isLoading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
                {!isLoading && categorizedCourses.length < 1 && (
                    <Typography sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign:'center', minHeight: '500px' }} variant="body1">
                        No completed courses to show at the moment. Your completed courses will appear here once you finish any assigned training.
                    </Typography>
                )}

                {categorizedCourses.map((category: CourseCategory) => (
                    <Box key={category.id} sx={{ mt: 4 }}>
                        <Typography variant="h5" sx={{ mb: 2, textDecoration: 'underline' }}>{category.label}</Typography>

                        <LearningCourseScrollMenu key={category.id} courses={category.courses.filter( course => course.user_did_complete)} />
                    </Box>
                ))}
            </Container>
        </Page>
    );
}

import { useState, useCallback, useEffect } from 'react';
import axios from 'src/utils/axios';
import { noCase } from 'change-case';
// @mui
import {
  Box,
  List,
  Badge,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../../utils/formatTime';
// components
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';
import MenuPopover from '../../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../../components/animate';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import useEcho from "../../../../hooks/useEcho";
import { getTypeLabel, ResourceType, UserNotification } from "../../../../@types/user_notification";

// ----------------------------------------------------------------------

export default function AnnouncementsPopover() {
  const isMountedRef = useIsMountedRef();
  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const [announcements, setAnnouncements] = useState([]);
  const [totalUnread, setTotalUnread] = useState(0);
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { subscribe } = useEcho();

  const getNotifications = useCallback(async () => {
    try {
      const response = await axios.get('/app/employee/dashboard/notifications', {
        params: {  },
      });

      if (isMountedRef.current) {
        let { data } = response.data;

        setTotalUnread(data.announcements.length + data.notifications.length);
        setAnnouncements(data.announcements);
        setNotifications(data.notifications);
      }
    } catch (err) {
      console.log(err);
    }
  }, [isMountedRef]);

  const setAnnouncementRead = useCallback(async (id) => {
    try {
      let url = `/app/employee/dashboard/company-announcements/${id}/read`;
      await axios.post(url);

      if (isMountedRef.current) {
        // nothing to do
      }
    } catch (err) {
      console.log(err);
    }
  }, [isMountedRef]);

  const setAllAnnouncementsRead = useCallback(async () => {
    try {
      let url = `/app/employee/dashboard/company-announcements/all-read`;
      await axios.post(url, {
        params: {  },
      });

      if (isMountedRef.current) {
        // nothing to do
      }
    } catch (err) {
      console.log(err);
    }
  }, [isMountedRef]);

  const setNotificationRead = useCallback(async (id) => {
    try {
      let url = `/app/employee/dashboard/notification/${id}/read`;
      await axios.post(url);

      if (isMountedRef.current) {
        // nothing to do
      }
    } catch (err) {
      console.log(err);
    }
  }, [isMountedRef]);

  const setAllNotificationsRead = useCallback(async () => {
    try {
      let url = `/app/employee/dashboard/notifications/all-read`;
      await axios.post(url);

      if (isMountedRef.current) {
        // nothing to do
      }
    } catch (err) {
      console.log(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect( () => {
    subscribe(
        (newNotification: UserNotification) => {
          const updatedNotifications = notifications.filter( (currentNotification: UserNotification) => currentNotification.id != newNotification.id);
          const resourceType = `TYPE_${newNotification.resource_type.toUpperCase()}` as ResourceType;
          newNotification = {...newNotification, title: getTypeLabel(resourceType), description: newNotification.message};
          const newNotifications: UserNotification[] = [...updatedNotifications, newNotification];
          setNotifications(newNotifications);
          setTotalUnread(announcements.length + newNotifications.length);
        },

        (error: any) => {
          console.error(error);
        }
    )
  }, []);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onNotificationClick = async (id: any) => {
    setNotificationRead(id);

    let notification = notifications.find(function(o: any){
      return o.id === id;
    });

    if(notification){
      setNotifications(notifications.filter((item: any) => item.id !== id));

      setTotalUnread(totalUnread - 1);

      navigateToNotification(notification);
    }
  };

  const handleMarkAsRead = async (id: any) => {
    setAnnouncementRead(id);

    var index = announcements.findIndex(function(o: any){
      return o.id === id;
    });

    if(index !== -1){
      setAnnouncements(announcements.filter((item: any) => item.id !== id));

      setTotalUnread(totalUnread - 1);
    }
  };

  const handleMarkAllAsRead = async () => {
    await setAllAnnouncementsRead();
    await setAllNotificationsRead();

    setAnnouncements([]);
    setNotifications([]);
    setTotalUnread(0);
  };

  const navigateToNotification = (noti: any) => {
    if(!noti){
      return;
    }

    let url = noti.app_url;

    if(url){
      window.location.href = url;
      handleClose();
    }
  }

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnread} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnread} unread messages
            </Typography>
          </Box>

          {totalUnread > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          { notifications.length > 0 && 
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Notifications
                </ListSubheader>
              }
            >
              {notifications.map((noti: UserNotification) => (
                <NotificationItem key={noti.id} notification={noti}  onClickCallback={onNotificationClick} />
              ))}
            </List>
          }

          { announcements.length > 0 && 
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Company Announcements
                </ListSubheader>
              }
            >
              {announcements.map((announcement: AnnouncementItemProps) => (
                <AnnouncementItem key={announcement.id} notification={announcement}  onClickCallback={handleMarkAsRead} />
              ))}
            </List>
          }
        </Scrollbar>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

type AnnouncementItemProps = {
  id: string;
  title: string;
  description: string;
  type: string;
  department_id: string;
  created_by: string;
  company_id: string;
  created_at: string;
  updated_at: string;
  status: string;
  is_unread: boolean;
};

function NotificationItem({ notification, onClickCallback }: { notification: UserNotification, onClickCallback: Function }) {
  const { id, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        // ...(notification.is_unread && {
        //   bgcolor: 'action.selected',
        // }),
      }}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}
      <ListItemText
        onClick={() => onClickCallback(id)} 
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.created_at)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

function AnnouncementItem({ notification, onClickCallback }: { notification: AnnouncementItemProps, onClickCallback: Function }) {
  const { id, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        // ...(notification.is_unread && {
        //   bgcolor: 'action.selected',
        // }),
      }}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}
      <ListItemText
        onClick={() => onClickCallback(id)} 
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.created_at)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: AnnouncementItemProps|UserNotification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description ?? '')}
      </Typography>
    </Typography>
  );

  return {
    id: notification.id,
    title
  };
}

import axios from "../utils/axios";
import {useState} from "react";

const useFlagFeatures = () => {

    const [canSeeIntegrationsTab, setCanSeeIntegrationsTab] = useState("false");


    const checkIntegrationsTab = async () => {

        const cached = localStorage.getItem('enabledUsers');
        if (cached && cached.length > 0) {
            setCanSeeIntegrationsTab(cached);
            return;
        }

        const res = await axios.get('/app/payroll/enabled-users');
        setCanSeeIntegrationsTab(res.data.data);
        localStorage.setItem('enabledUsers', res.data.data);
    };

    return {
        canSeeIntegrationsTab,
        checkIntegrationsTab
    }

};

export default useFlagFeatures;
import { useRef, useEffect, useState, useCallback } from 'react';
import axios from 'src/utils/axios';
// @mui
import {
    Box, DialogActions, DialogTitle, Button, Typography, Divider, Dialog
} from '@mui/material';
// components
import { ConfirmationDialog } from '../../../../components/dialogs';
import Iconify from '../../../../components/Iconify';
// pdftron
import WebViewer from '@pdftron/webviewer';
//util
import { base64ToBlob } from '../../../../utils/fileUtil';
// config
import { APRYSE_LICENSE_KEY, ROLE } from '../../../../config';
import { companyAnnotationProps, employeeAnnotationProps } from 'src/sections/admin/custom-doc/CustomDocMultiSignForm';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

type OnboardingDoc = {
    id: number;
    created_by: number;
    custom_doc_user_id: number | null;
    company_id: number;
    name: string;
    is_onboarding: number;
    get_file_url: string;
    created_at: string;
    updated_at: string;
    custom_document?: any;
    path?: string;
    user_id?: number;
}

type ConfirmDialogState = {
    isOpen: boolean;
    resolve: (value: any) => void;
}

const initConfirmDialogState: ConfirmDialogState = {
    isOpen: false,
    resolve: (confirmed: boolean) => { }
}

const initConfirmCancelDialogState: ConfirmDialogState = {
    isOpen: false,
    resolve: (confirmed: boolean) => { }
}

const initialDocBlob: any = null;

type FormModalState = {
    isOpenModal: boolean;
};

const initialFormModalState: FormModalState = {
    isOpenModal: false,
};

type Props = {
    doc: OnboardingDoc;
    isOpen: boolean;
    onCancel: Function;
    onComplete: (doc: OnboardingDoc | null) => void;
    postUrl?: string|null;
    signType?: string;
};

// ----------------------------------------------------------------------

export default function OnboardingDocFormDialog({ doc, isOpen, onCancel, onComplete, postUrl, signType = 'employee' }: Props) {
    const viewer = useRef(null);
    const [confirmDialogState, setConfirmDialogState] = useState(initConfirmDialogState);
    const [confirmCancelDialogState, setConfirmCancelDialogState] = useState(initConfirmCancelDialogState);
    const [documentBlob, setDocumentBlob] = useState(initialDocBlob);
    const [formModalState, setFormModalState] = useState(initialFormModalState);
    const finishBtn = useRef(null);
    const { user } = useAuth();

    useEffect(() => {
        setFormModalState({ isOpenModal: isOpen });
    }, [isOpen]);

    const getCustomDoc = useCallback(async (docId: number) => {
        try {
            // Diferenciar rutas para empleados y para admin
            let url = '';

            const parts = doc.path?.split('/');
            console.log(":doc", parts)
            var thirdElement = parts && parts.length > 1 ? parts[2] : 'custom-doc';

            if (thirdElement === 'custom-docs') {
                url = `/app/employee/custom-doc/${docId}/base64`;
            }

            if (thirdElement === 'custom-doc-users') {
                url = `/app/admin/custom-doc-user/${docId}/getBase64`;
            }

            // Access the third element

            let _params = {};

            const response = await axios.get(url, {
                params: _params
            });
            let { data } = response.data;

            const blobData = base64ToBlob(data.pdf_base64);

            setDocumentBlob(blobData);
        } catch (err) {
            console.log(err);
        }
    }, []);

    const storeCustomFile = useCallback(async (blob: any) => {
        try {
            const formData = new FormData();
            formData.append('file', blob, 'mydoc.pdf');

            console.log("doc: ", doc)
            console.log("signType: ", signType)

            // Handle admin sign as if he was an employee
            if (signType === 'employee' && doc.custom_document) {
                const customDocId = doc.custom_document.id.toString();
                formData.append('custom_doc_id',  customDocId);
                console.log("formData1: ", formData)
            }

            // Handle employee sign
            if (signType === 'employee' && !doc.custom_document) {
                const docId = doc.id.toString();
                formData.append('custom_doc_id',  docId);
                console.log("formData2: ", formData)
            }

            // Handle company sign
            if (signType === 'company') {
                const customDocUserId = doc.id.toString();
                formData.append('custom_doc_id',  customDocUserId);
                console.log("formData3: ", formData)
            }

            console.log("formData: ", formData);

            let url = postUrl ? postUrl : `/app/employee/custom-doc`;
            const response = await axios.post(url, formData);
            onComplete(response.data.data);

        } catch (err) {
            console.log(err);
        };
    }, [onComplete, doc]);

    const allowedRoles = [ROLE.ADMIN, ROLE.MANAGER];
    const isAdmin = (user: any) => user && allowedRoles.includes(user.role_id.toString());
    const isEmployee = (user: any) => user.role_id.toString() === ROLE.EMPLOYEE;
    const isAssigned = (user: any, doc: any) => {
        console.log(":user", user, doc)
        return user && user.id == doc.user_id;
    };

    const initPdfTron = useCallback((docBlob) => {
        WebViewer(
            {
                licenseKey: APRYSE_LICENSE_KEY,
                path: '/pdftron',
                // initialDoc: '/pdf/get_started.pdf'
                // initialDoc: '/pdf/get_started_edited.pdf'
            },
            // @ts-ignore
            viewer.current,
        ).then((instance) => {
            instance.UI.loadDocument(docBlob, { filename: "test.pdf" });

            instance.Core.documentViewer.addEventListener('annotationsLoaded', () => {
                const { Annotations, documentViewer } = instance.Core;
                const annotationManager = documentViewer.getAnnotationManager();
                const annotationsList = annotationManager.getAnnotationsList();
                const annotsToDraw: any = [];

                annotationsList.map(async (annot: any, index: any) => {

                    // flag as read only if the annotation has data
                    if (annot.ed && !['m-d-yyyy', 'Off', 'Yes'].includes(annot.ed)) {
                        annot.ReadOnly = true;
                    }

                    let backgroundColor = employeeAnnotationProps.textColor;
                    let label = employeeAnnotationProps.labelText;
                    if(annot.fieldName && annot.fieldName.includes(companyAnnotationProps.labelText)) {
                        backgroundColor = companyAnnotationProps.textColor
                        label = companyAnnotationProps.labelText
                    }

                    if (annot instanceof Annotations.SignatureWidgetAnnotation) {
                            
                        annot.createSignHereElement = () => {
                            const div = document.createElement('div');
                            div.style.width = '100%';
                            div.style.height = '100%';
                            div.style.cursor = 'pointer';
                            div.style.background = 'white';
                            div.style.display = 'flex';
                            div.style.alignItems = 'center';
                            div.style.justifyContent = 'center';

                            div.innerHTML = `<span style="background:${backgroundColor}">${label} SIGNATURE</span>`;
        
                            return div;
                          };
                    }
                    
                    // customize styles of the form  field
                    // @ts-ignore
                    Annotations.WidgetAnnotation.getCustomStyles = function (widget: any) {
                        if (widget.ReadOnly) {
                            return {
                                pointerEvents: 'none',
                                cursor: 'not-allowed',
                            };
                        }

                        let borderColor = employeeAnnotationProps.textColor;

                        if(widget.fieldName && widget.fieldName.includes(companyAnnotationProps.labelText)) {
                            borderColor = companyAnnotationProps.textColor
                        }

                        if (widget && widget.fieldName) {
                            const widgetType = widget.fieldName.split('_');
                            const signer = widgetType[1];

                            console.log(isEmployee(user), isAdmin(user), isAssigned(user, doc), signer);

                            // if (widgetType[1] != 'SIGNER1' && widgetType[1] != 'SIGNER2') {
                            //     return;
                            // }

                            if (isEmployee(user) && signer == "SIGNER2") {
                                // we dont show the company signature only the employee's signature field
                                return {
                                    display: 'none'
                                };
                            }


                            // Handle admin logic
                            if (isAdmin(user) && isAssigned(user, doc) && signer == "SIGNER1") {
                                // can sign
                            }

                            if (isAdmin(user) && isAssigned(user, doc) && signer == "SIGNER2") {
                                return {
                                    display: 'none'
                                };
                            }

                            if (isAdmin(user) && !isAssigned(user, doc) && signer == "SIGNER1") {
                                // can only see signer 1, cant modify
                                widget.fieldFlags.set('ReadOnly', true);
                            }

                            if (isAdmin(user) && !isAssigned(user, doc) && signer == "SIGNER2") {
                                // can sign
                            }
                        }

                        if (widget instanceof Annotations.TextWidgetAnnotation) {
                            return {
                                border: `1px solid ${borderColor}`,
                            };
                        }
                    };

                    // @ts-ignore
                    Annotations.WidgetAnnotation.getCustomStyles(annot);

                    // draw the annotation the viewer
                    annotsToDraw.push(annot);
                });

                annotationManager.drawAnnotationsFromList(annotsToDraw);
            });

            const { documentViewer, annotationManager } = instance.Core;
            // you can now call WebViewer APIs here...

            var { Feature } = instance.UI;
            instance.UI.enableFeatures([Feature.FilePicker]);
            instance.UI.disableFeatures([Feature.NotesPanel]);
            instance.UI.disableElements(['toolbarGroup-View', 'menuButton', 'viewControlsButton', 'searchButton', 'panToolButton', 'leftPanelButton', 'toolbarGroup-Forms', 'selectToolButton']);

            // @ts-ignore
            finishBtn.current.onclick = async (e: any) => {
                const confirmed: any = await openConfirmSave();

                if (!confirmed) {
                    setConfirmDialogState(initConfirmDialogState);
                    return;
                }

                const doc = documentViewer.getDocument();
                // doc.FlattenAnnotations()
                const xfdfString = await annotationManager.exportAnnotations();
                const data = await doc.getFileData({
                    // saves the document with annotations in it
                    xfdfString,
                    flatten: true,
                    finishedWithDocument: true,
                });

                const arr = new Uint8Array(data);
                const blob = new Blob([arr], { type: 'application/pdf' });

                storeCustomFile(blob);
            };

            instance.UI.setHeaderItems(function (header) {
                // header.getHeader('toolbarGroup-Annotate').push({ type: 'toolGroupButton', toolGroup: 'lineTools', dataElement: 'lineToolGroupButton', title: 'annotation.line' });

                // instance.UI.setToolbarGroup(instance.UI.ToolbarGroup.FORMS);
                instance.UI.setToolbarGroup(instance.UI.ToolbarGroup.VIEW);

                for (let i = 9; i >= 0; i--) {
                    header.getHeader(instance.UI.ToolbarGroup.ANNOTATE).delete(i);
                }
                for (let i = 10; i >= 0; i--) {
                    header.getHeader(instance.UI.ToolbarGroup.SHAPES).delete(i);
                }
                for (let i = 5; i >= 0; i--) {
                    header.getHeader(instance.UI.ToolbarGroup.INSERT).delete(i);
                }
                for (let i = 1; i >= 0; i--) {
                    header.getHeader(instance.UI.ToolbarGroup.EDIT).delete(i);
                }
                for (let i = 3; i >= 0; i--) {
                    header.getHeader(instance.UI.ToolbarGroup.FILL_AND_SIGN).delete(i);
                }
            });
        });
    }, [storeCustomFile]);

    useEffect(() => {
        if (doc) {
            getCustomDoc(doc.id);
        }
    }, [getCustomDoc, doc]);

    useEffect(() => {
        if (documentBlob) {
            initPdfTron(documentBlob);
        }
    }, [initPdfTron, documentBlob]);

    const openConfirmSave = () => new Promise((resolve, reject) => {
        setConfirmDialogState({ isOpen: true, resolve });
    });

    const openConfirmCancelDialog = () => new Promise((resolve, reject) => {
        setConfirmCancelDialogState({ isOpen: true, resolve });
    });

    const onConfirmCancelDialog = async () => {
        const confirmed: any = await openConfirmCancelDialog();

        setConfirmCancelDialogState(initConfirmCancelDialogState);

        if (confirmed) {
            onCancel();
            return;
        } else {
        }
    }

    return (
        <Dialog maxWidth="xl" fullWidth={true} open={formModalState.isOpenModal} onClose={onConfirmCancelDialog}>
            <DialogTitle sx={{ mb: 1 }}>
                {doc?.name}
                <Typography variant="body2">Onboarding Document</Typography>
            </DialogTitle>

            <Divider sx={{ mt: 0, m: 2 }} />

            <Box sx={{ m: 2, maxHeight: '800px', overflow: 'scroll' }}>
                <div className="MyComponent">
                    <div className="webviewer" ref={viewer} style={{ height: "90vh" }} />
                </div>
            </Box>

            <ConfirmationDialog
                title="Save Document"
                message="Are you sure you want to save this document?"
                open={confirmDialogState.isOpen}
                onCloseConfirm={confirmDialogState.resolve}
            />

            <ConfirmationDialog
                title="Leave Document?"
                message="Changes you made may not be saved."
                open={confirmCancelDialogState.isOpen}
                onCloseConfirm={confirmCancelDialogState.resolve}
            />

            <Divider sx={{ m: 2, mb: 0 }} />

            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <Button variant="contained" color="primary" ref={finishBtn}>
                        <Iconify icon={'material-symbols:done'} width={25} height={25} sx={{ verticalAlign: 'middle', mr: 1 }} />
                        Finish
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}

import { useRef, useEffect, useState, useCallback } from 'react';
import axios from 'src/utils/axios';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
    Box, Grid, Typography, Stack, Button, CircularProgress, Backdrop,
    Menu, MenuItem, MenuProps
} from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
// sections
import { SelectTemplateDialog, ConfirmSaveDocDialog } from '.';
// PDFTron
import WebViewer from '@pdftron/webviewer';
// @types
import { CustomDoc, DocumentType } from '../../../@types/user';
//util
import { base64ToBlob } from '../../../utils/fileUtil';
// config
import { DocInputTypeEnum, APRYSE_LICENSE_KEY } from '../../../config';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';


// ----------------------------------------------------------------------

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 200,
        color: 'rgb(55, 65, 81)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

type DocumentCategoryType = {
    id: string;
    text_id: string;
    name: string;
    type: string;
    unique_id: string;
}

type Props = {
    doc: CustomDoc | null;
    categories: DocumentCategoryType[];
    forcedCategory: string;
    onComplete: (doc: CustomDoc | null) => void;
};

const GET_STARTED_DOC_PATH = '/pdf/get_started.pdf';

type ConfirmSaveModalState = {
    isOpen: boolean;
    resolve: (value: any) => void;
}

const initConfirmSaveModalState: ConfirmSaveModalState = {
    isOpen: false,
    resolve: () => { }
}

type AnnotationCustomProps = {
    textColor : string;
    labelText: string,
}

export const companyAnnotationProps: AnnotationCustomProps = {
    textColor: '#7DDA58',
    labelText: 'SIGNER2',
}

export const employeeAnnotationProps: AnnotationCustomProps = {
    textColor: '#3d56e5',
    labelText: 'SIGNER1',
}

// ----------------------------------------------------------------------

export default function CustomDocMultiSignForm({ doc, categories, forcedCategory, onComplete }: Props) {
    const isMountedRef = useIsMountedRef();
    const [docInstance, setDocInstance] = useState<any>({});
    const [confirmSaveOpenState, setConfirmSaveState] = useState(initConfirmSaveModalState);
    const [templateDialogState, setTemplateDialogState] = useState({ isOpen: false });
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [addDocAnchorEl, setAddDocAnchorEl] = useState<null | HTMLElement>(null);
    const addDocOpen = Boolean(addDocAnchorEl);
    const [isDocumentReady, setIsDocumentReady] = useState('no-ready');

    const viewer = useRef(null);
    const filePicker = useRef(null);

    const storeCustomFile = useCallback(async (blob: any, docName: string, category: DocumentCategoryType, isDoubleSigned) => {
        try {
            const formData = new FormData();
            formData.append('file', blob, 'mydoc.pdf');
            formData.append('name', docName);
            formData.append("type", category.type);
            formData.append("type_id", category.id);
            formData.append('is_multi_signature', String(isDoubleSigned));

            let url = `/app/admin/custom-doc`;

            if (doc !== null) {
                url = `/app/admin/custom-doc/${doc.id}`;
                formData.append('_method', 'PUT');
            }

            const response = await axios.post(url, formData);
            onComplete(response.data.data)
        } catch (err) {
            console.log(err);
        };
    }, [onComplete, doc]);

    const applyFields = async () => {
        const { Annotations, documentViewer } = docInstance.Core;
        const annotationManager = documentViewer.getAnnotationManager();
        const fieldManager = annotationManager.getFieldManager();
        const annotationsList = annotationManager.getAnnotationsList();
        const annotsToDelete: any = [];
        const annotsToDraw: any = [];

        await Promise.all(
            annotationsList.map(async (annot: any, index: any) => {
                let inputAnnot;
                let field;

                if (typeof annot.custom !== 'undefined') {
                    // create a form field based on the type of annotation
                    if (annot.custom.type === DocInputTypeEnum.text) {
                        field = new Annotations.Forms.Field(
                            annot.getContents() + Date.now() + index,
                            {
                                type: 'Tx',
                                value: annot.custom.value,
                                font: {
                                    name: 'Helvetica',
                                    size: annot.Height / 2 // did guess and check to find this font size
                                }
                            },
                        );
                        inputAnnot = new Annotations.TextWidgetAnnotation(field, {
                            transparentBackground: false,
                            appearance: '_DEFAULT',
                            appearances: {
                                _DEFAULT: {
                                    Normal: {
                                        data:
                                            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
                                        offset: {
                                            x: 100,
                                            y: 100,
                                        },
                                    },
                                },
                            },
                        });
                    } else if (annot.custom.type === DocInputTypeEnum.signature) {
                        field = new Annotations.Forms.Field(
                            annot.getContents() + Date.now() + index,
                            {
                                type: 'Sig',
                            },
                        );
                        inputAnnot = new Annotations.SignatureWidgetAnnotation(field, {
                            appearance: '_DEFAULT',
                            appearances: {
                                _DEFAULT: {
                                    Normal: {
                                        data:
                                            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
                                        offset: {
                                            x: 100,
                                            y: 100,
                                        },
                                    },
                                },
                            },
                        });
                    } else if (annot.custom.type === DocInputTypeEnum.date) {
                        field = new Annotations.Forms.Field(
                            annot.getContents() + Date.now() + index,
                            {
                                type: 'Tx',
                                value: 'm-d-yyyy',
                                font: {
                                    name: 'Helvetica',
                                    size: annot.Height / 2 // did guess and check to find this font size
                                },
                                // Actions need to be added for DatePickerWidgetAnnotation to recognize this field.
                                actions: {
                                    F: [
                                        {
                                            name: 'JavaScript',
                                            // You can customize the date format here between the two double-quotation marks
                                            // or leave this blank to use the default format
                                            javascript: 'AFDate_FormatEx("mmm d, yyyy");',
                                        },
                                    ],
                                    K: [
                                        {
                                            name: 'JavaScript',
                                            // You can customize the date format here between the two double-quotation marks
                                            // or leave this blank to use the default format
                                            javascript: 'AFDate_FormatEx("mmm d, yyyy");',
                                        },
                                    ],
                                },
                            },
                        );

                        inputAnnot = new Annotations.DatePickerWidgetAnnotation(field);
                    } else {
                        // exit early for other annotations
                        annotationManager.deleteAnnotation(annot, false, true); // prevent duplicates when importing xfdf
                        return;
                    }
                } else {
                    // exit early for other annotations
                    return;
                }

                // set position
                inputAnnot.PageNumber = annot.getPageNumber();
                inputAnnot.X = annot.getX();
                inputAnnot.Y = annot.getY();
                inputAnnot.rotation = annot.Rotation;
                if (annot.Rotation === 0 || annot.Rotation === 180) {
                    inputAnnot.Width = annot.getWidth();
                    inputAnnot.Height = annot.getHeight();
                } else {
                    inputAnnot.Width = annot.getHeight();
                    inputAnnot.Height = annot.getWidth();
                }

                // delete original annotation
                annotsToDelete.push(annot);

                // customize styles of the form field
                Annotations.WidgetAnnotation.getCustomStyles = function (widget: any) {
                    if (widget instanceof Annotations.SignatureWidgetAnnotation) {
                        return {
                            border: '1px solid #a5c7ff',
                        };
                    } else if (widget instanceof Annotations.TextWidgetAnnotation) {
                        return {
                            border: '1px solid #a5c7ff',
                        };
                    }
                };
                Annotations.WidgetAnnotation.getCustomStyles(inputAnnot);

                // draw the annotation the viewer
                annotationManager.addAnnotation(inputAnnot);
                fieldManager.addField(field);
                annotsToDraw.push(inputAnnot);
            }),
        );

        // delete old annotations
        annotationManager.deleteAnnotations(annotsToDelete, null, true);

        // refresh viewer
        await annotationManager.drawAnnotationsFromList(annotsToDraw);
        // await uploadForSigning();
    };

    const addField = useCallback((
        type: any,
        annotationProps: AnnotationCustomProps,
        point: any = {},
        name: string = '',
        value: string = '',
        flag: any = {},
        height: any = null,
        width: any = null,
        pageNum: any = null,
        instance: any = null
    ) => {
        let documentViewer;
        let Annotations;

        // have to do this assignment workaround to avoid 
        //   typescript warning about "prefer object destructuring"
        if (instance !== null) {
            const { documentViewer: dv, Annotations: an } = instance.Core;
            documentViewer = dv;
            Annotations = an;
        } else if (docInstance !== null) {
            const { documentViewer: dv, Annotations: an } = docInstance.Core;
            documentViewer = dv;
            Annotations = an;
        } else {
            console.error('docInstance is null');
        }

        const doc = documentViewer.getDocument();

        const annotationManager = documentViewer.getAnnotationManager();
        const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
        const page = displayMode.getSelectedPages(point, point);

        if (pageNum !== null) {
            page.first = pageNum;
        }

        if (!!point.x && page.first == null) {
            console.log("don't add field to an invalid page location");
            return; // don't add field to an invalid page location
        }

        const page_idx = page.first !== null ? page.first : documentViewer.getCurrentPage();
        const page_info = doc.getPageInfo(page_idx);
        const page_point = displayMode.windowToPage(point, page_idx);
        const zoom = documentViewer.getZoomLevel();

        var textAnnot = new Annotations.FreeTextAnnotation();
        textAnnot.PageNumber = page_idx;
        const rotation = documentViewer.getCompleteRotation(page_idx) * 90;
        textAnnot.Rotation = rotation;

        if (height !== null && width !== null) {
            textAnnot.X = point.x;
            textAnnot.Y = point.y
            textAnnot.Width = width;
            textAnnot.Height = height;
        } else {
            if (rotation === 270 || rotation === 90) {
                textAnnot.Width = 50.0 / zoom;
                textAnnot.Height = 250.0 / zoom;
            } else {
                textAnnot.Width = 250.0 / zoom;
                textAnnot.Height = 50.0 / zoom;
            }

            textAnnot.FontSize = '' + 20.0 / zoom + 'px';

            textAnnot.X = (page_point.x || page_info.width / 2) - textAnnot.Width / 2;
            textAnnot.Y = (page_point.y || page_info.height / 2) - textAnnot.Height / 2;

            if (annotationProps.labelText === 'SIGNER2') {
                textAnnot.Y = textAnnot.Y + 200;
            }
        }

        textAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));
        textAnnot.custom = {
            type,
            value,
            flag,
            name: `_${annotationProps.labelText}_${type}_`,
        };

        // set the type of annot
        textAnnot.setContents(textAnnot.custom.name);
        textAnnot.FontSize = '' + 20.0 / zoom + 'px';
        textAnnot.FillColor = new Annotations.Color(211, 211, 211, 0.5);
        textAnnot.TextColor = new Annotations.Color(annotationProps.textColor);
        textAnnot.StrokeThickness = 1;
        textAnnot.StrokeColor = new Annotations.Color(0, 165, 228);
        textAnnot.TextAlign = 'center';

        textAnnot.Author = annotationManager.getCurrentUser();

        annotationManager.deselectAllAnnotations();
        annotationManager.addAnnotation(textAnnot, true);
        annotationManager.redrawAnnotation(textAnnot);
        annotationManager.selectAnnotation(textAnnot);
    }, [docInstance]);

    const initPdfTron = useCallback((docBlob: any) => {
        WebViewer(
            {
                // initialDoc: GET_STARTED_DOC_PATH,
                licenseKey: APRYSE_LICENSE_KEY,
                path: '/pdftron',
                disabledElements: [
                    'ribbons',
                    'toggleNotesButton',
                    'searchButton',
                    'menuButton',
                ],
            },
            // @ts-ignore
            viewer.current,
        ).then(instance => {
            setDocInstance(instance);

            // select only the view group
            instance.UI.setToolbarGroup('toolbarGroup-View');

            instance.Core.documentViewer.addEventListener('annotationsLoaded', () => {
                // perform document operations
                const annotsToDelete: any = [];
                const { documentViewer } = instance.Core;
                const annotationManager = documentViewer.getAnnotationManager();
                // const fieldManager = annotationManager.getFieldManager();

                annotationManager.getAnnotationsList().forEach(annotation => {
                    let annotName = '';

                    // @ts-ignore
                    if (!annotation.Ua || !annotation.Ua.Ed) {
                        annotName = Math.random().toString(36).substring(7).toUpperCase(); // assign random string
                    } else {
                        // @ts-ignore
                        annotName = annotation.Ua.Ed.toUpperCase(); // not sure why I have to get the field id this way?
                    }

                    let type = '';
                    if (annotName.includes(DocInputTypeEnum.signature)) {
                        type = DocInputTypeEnum.signature;
                    } else if (annotName.includes(DocInputTypeEnum.text)) {
                        type = DocInputTypeEnum.text;
                    } else if (annotName.includes(DocInputTypeEnum.date)) {
                        type = DocInputTypeEnum.date;
                    } else {
                        // default to text
                        type = DocInputTypeEnum.text;
                    }

                    // @ts-ignore
                    const point = {
                        x: annotation.X,
                        y: annotation.Y
                    };
                    // const name = annotation.custom.name;
                    const name = annotation.getContents();
                    const value = '';
                    // const flag = annotation.custom.flag;
                    const flag = '';
                    const pageNum = annotation.PageNumber;

                    let annotationProps = employeeAnnotationProps;

                    if (!annotName.includes(employeeAnnotationProps.labelText) && !annotName.includes(companyAnnotationProps.labelText)) {
                        annotationProps = employeeAnnotationProps
                    }
                    
                    if (annotName.includes(companyAnnotationProps.labelText)) {
                        annotationProps = companyAnnotationProps;
                    }

                    if (annotName.includes(employeeAnnotationProps.labelText)) {
                        annotationProps = employeeAnnotationProps;
                    }

                    // @ts-ignore
                    // only add fields that are not already prefilled
                    if (!annotation.ed) {
                        // delete original annotation
                        annotsToDelete.push(annotation);
                        // add field
                        addField(type, annotationProps, point, name, value, flag, annotation.Height, annotation.Width, pageNum, instance);
                    }
                });

                annotationManager.deleteAnnotations(annotsToDelete);

            });

            if (docBlob) {
                instance.UI.loadDocument(docBlob, { filename: "test.pdf" });
            } else {
                instance.UI.loadDocument(GET_STARTED_DOC_PATH);
            }

            setIsDocumentReady('ready');
            //   const { iframeWindow } = instance;

            // @ts-ignore
            filePicker.current.onchange = (e: any) => {
                const file = e.target.files[0];
                if (file) {
                    instance.UI.loadDocument(file);
                }
            };
        });
        // disabling for addField() dependency
        // From https://stackoverflow.com/a/55854902
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const uploadDoc = async () => {
        const { docName, category }: any = await openConfirmSave();
        closeConfirmSave();

        if (docName) {
            setLoadingOpen(true);
            await applyFields();

            const { documentViewer, annotationManager } = docInstance.Core;

            const doc = documentViewer.getDocument();
            const xfdfString = await annotationManager.exportAnnotations({ widgets: true, fields: true });
            const data = await doc.getFileData({
                // saves the document with annotations in it
                xfdfString
            });
            
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: 'application/pdf' });

            let isDoubleSigned = 0;
            annotationManager.getAnnotationsList().map(async (annot: any, index: any) => {
                if (annot.fieldName.includes(companyAnnotationProps.labelText)) {
                    isDoubleSigned = 1;
                }
            })

            await storeCustomFile(blob, docName, category, isDoubleSigned);
            setLoadingOpen(false);
        }
    };

    const openConfirmSave = useCallback(() => new Promise((resolve, reject) => {
        setConfirmSaveState({ isOpen: true, resolve });
    }), []);

    const closeConfirmSave = useCallback(() => {
        setConfirmSaveState(initConfirmSaveModalState);
    }, []);

    const getCustomDoc = useCallback(async (docId: number) => {
        try {
            let url = `/app/admin/custom-doc/${docId}/base64`;

            let _params = {};

            const response = await axios.get(url, {
                params: _params
            });

            let { data } = response.data;

            const blobData = base64ToBlob(data.pdf_base64);
            // const blobData = new Blob([atob(data.pdf_base64.split(',')[1])], {type: 'application/pdf'});
            initPdfTron(blobData);
        } catch (err) {
            console.log(err);
        }
    }, [initPdfTron]);

    const initPage = useCallback(async () => {
        if (doc !== null) {
            getCustomDoc(doc.id);
        } else {
            initPdfTron(null);
        }
    }, [doc, getCustomDoc, initPdfTron]);

    /*****************
     * Template Menu *
     *****************/

    const getTemplateDownloadUrl = useCallback(async (item: DocumentType) => {
        try {
            let url = item.get_file_url;
            const response = await axios.get(url);

            if (isMountedRef.current) {
                let { url } = response.data.data;
                return url;
            }
        } catch (err) {
            console.log(err);
            return '';
        }
    }, [isMountedRef]);

    const loadTemplateDoc = useCallback(async (doc: DocumentType) => {
        try {
            setLoadingOpen(true);

            const downloadUrl = await getTemplateDownloadUrl(doc);

            const response = await axios.get(downloadUrl, { responseType: 'arraybuffer' });

            const blobData = new Blob([response.data]);

            docInstance.UI.loadDocument(blobData, { filename: "template.pdf" });

            setLoadingOpen(false);
        } catch (err) {
            console.log(err);
            setLoadingOpen(false);
        }
    }, [docInstance, getTemplateDownloadUrl]);

    useEffect(() => {
        initPage();
    }, [initPage]);

    /****************
     * Add Doc Menu *
     ****************/

    const handleAddDocClick = (event: React.MouseEvent<HTMLElement>) => {
        setAddDocAnchorEl(event.currentTarget);
    };

    const handleAddDocClose = () => {
        setAddDocAnchorEl(null);
    };

    const onAddDocClick = (event: React.MouseEvent<HTMLElement>) => {
        // @ts-ignore
        filePicker.current.click();
        handleAddDocClose();
    };

    /**************************
     * Select Template Dialog *
     **************************/

    const onTemplateAddClick = () => {
        openTemplateDialog();
        handleAddDocClose();
    };

    const openTemplateDialog = useCallback(() => {
        setTemplateDialogState({ isOpen: true });
    }, []);

    const closeTemplateDialog = useCallback(() => {
        setTemplateDialogState({ isOpen: false });
    }, []);

    const onTemplateSelect = useCallback(async (doc: DocumentType) => {
        closeTemplateDialog();
        loadTemplateDoc(doc);
    }, [loadTemplateDoc, closeTemplateDialog]);

    return (
        <div>
            <input type="hidden" id={'document-ready'} value={isDocumentReady}/>
            <Grid container>
                <Grid item xs={12} sm={12} md={2}>
                    <Box>
                        <Typography variant="h6">Prepare Document</Typography>
                    </Box>
                    <Box>
                        <Grid item gap={1}>
                            <Stack>
                                <Box padding={1}>
                                    <Typography variant="subtitle2">Step 1</Typography>
                                </Box>

                                <Box padding={1} pl={0}>
                                    <div>
                                        <Button
                                            id="demo-customized-button"
                                            aria-controls={addDocOpen ? 'demo-customized-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={addDocOpen ? 'true' : undefined}
                                            variant="contained"
                                            sx={{ ...MY_STYLE.doc_btn }}
                                            onClick={handleAddDocClick}
                                        >
                                            <Iconify icon="material-symbols:keyboard-arrow-down" width={24} height={24} />
                                            <Box sx={{ ...MY_STYLE.doc_btn_label }}>
                                                Upload
                                            </Box>
                                        </Button>
                                        <StyledMenu
                                            id="demo-customized-menu"
                                            MenuListProps={{ 'aria-labelledby': 'demo-customized-button', }}
                                            anchorEl={addDocAnchorEl}
                                            open={addDocOpen}
                                            onClose={handleAddDocClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5,
                                                    width: '20ch',
                                                },
                                            }}
                                        >
                                            <MenuItem value="file_picker" onClick={onAddDocClick}>
                                                <Iconify icon="material-symbols:drive-folder-upload-outline" width={20} height={20} mr={1} />
                                                Desktop
                                            </MenuItem>
                                            <MenuItem value="template" onClick={onTemplateAddClick}>
                                                <Iconify icon="material-symbols:text-snippet-outline" width={20} height={20} mr={1} />
                                                Template
                                            </MenuItem>
                                        </StyledMenu>
                                    </div>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack>
                                <Box padding={1}>
                                    <Typography variant="subtitle2">Step 2</Typography>
                                </Box>
                                <Box style={{paddingLeft: '8px'}}>
                                    <small>Signer 1</small>
                                </Box>
                                <Box padding={1} pl={0}>
                                    <Button
                                        variant="contained"
                                        sx={{ ...MY_STYLE.doc_btn }}
                                        id={"signer1"}
                                        onClick={() => addField(DocInputTypeEnum.signature, employeeAnnotationProps)}
                                    >
                                        <Iconify icon="material-symbols:add" width={20} height={20} />
                                        <Box sx={{ ...MY_STYLE.doc_btn_label }}>
                                            Signature
                                        </Box>
                                    </Button>
                                </Box> 

                                <Box padding={1} pl={0}>
                                    <Button
                                        variant="contained"
                                        sx={{ ...MY_STYLE.doc_btn }}
                                        onClick={() => addField(DocInputTypeEnum.text, employeeAnnotationProps)}
                                    >
                                        <Iconify icon="material-symbols:add" width={20} height={20} />
                                        <Box sx={{ ...MY_STYLE.doc_btn_label }}>
                                            Text
                                        </Box>
                                    </Button>
                                </Box>

                                <Box padding={1} pl={0}>
                                    <Button
                                        variant="contained"
                                        sx={{ ...MY_STYLE.doc_btn }}
                                        onClick={() => addField(DocInputTypeEnum.date, employeeAnnotationProps)}
                                    >
                                        <Iconify icon="material-symbols:add" width={20} height={20} />
                                        <Box sx={{ ...MY_STYLE.doc_btn_label }}>
                                            Date
                                        </Box>
                                    </Button>
                                </Box>

                                <Box style={{paddingLeft: '8px'}}>
                                    <small>Signer 2</small>
                                </Box>
                                <Box padding={1} pl={0}>
                                    <Button
                                        variant="outlined"
                                        id={"signer2"}
                                        sx={{ ...MY_STYLE.doc_btn }}
                                        onClick={() => addField(DocInputTypeEnum.signature, companyAnnotationProps)}
                                    >
                                        <Iconify icon="material-symbols:add" width={20} height={20} />
                                        <Box sx={{ ...MY_STYLE.doc_btn_label }}>
                                            Signature
                                        </Box>
                                    </Button>
                                </Box>
                                <Box padding={1} pl={0}>
                                    <Button
                                        variant="outlined"
                                        sx={{ ...MY_STYLE.doc_btn }}
                                        onClick={() => addField(DocInputTypeEnum.text, companyAnnotationProps)}
                                    >
                                        <Iconify icon="material-symbols:add" width={20} height={20} />
                                        <Box sx={{ ...MY_STYLE.doc_btn_label }}>
                                            Text
                                        </Box>
                                    </Button>
                                </Box>
                                <Box padding={1} pl={0}>
                                    <Button
                                        variant="outlined"
                                        sx={{ ...MY_STYLE.doc_btn }}
                                        onClick={() => addField(DocInputTypeEnum.date, companyAnnotationProps)}
                                    >
                                        <Iconify icon="material-symbols:add" width={20} height={20} />
                                        <Box sx={{ ...MY_STYLE.doc_btn_label }}>
                                            Date
                                        </Box>
                                    </Button>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid>
                            <Stack>
                                <Box padding={1}>
                                    <Typography variant="subtitle2">Step 3</Typography>
                                </Box>
                                <Box padding={1} pl={0}>
                                    <Button
                                        variant="contained"
                                        sx={{ ...MY_STYLE.doc_btn }}
                                        onClick={uploadDoc}
                                    >
                                        <Iconify icon="material-symbols:backup" width={20} height={20} />
                                        <Box sx={{ ...MY_STYLE.doc_btn_label }}>
                                            Save
                                        </Box>
                                    </Button>

                                </Box>
                            </Stack>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                    {/* <div className="webviewer" ref={viewer}></div> */}
                    <div className="webviewer" ref={viewer} style={{ height: "90vh" }} />
                </Grid>
            </Grid>

            <input type="file" ref={filePicker} style={{ display: 'none' }} />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingOpen}
            >
                <CircularProgress color="primary" />
            </Backdrop>

            <input
                type="file"
                ref={filePicker}
                accept="application/pdf"
                style={{ display: 'none' }}
            />

            <ConfirmSaveDocDialog
                open={confirmSaveOpenState.isOpen}
                name={doc !== null ? doc.name : ''}
                type={doc !== null ? doc.type : ''}
                typeId={doc !== null ? doc.type_id : ''}
                categories={categories}
                forcedCategory={forcedCategory}
                onCloseConfirm={confirmSaveOpenState.resolve}
            />

            <SelectTemplateDialog
                open={templateDialogState.isOpen}
                onCloseConfirm={closeTemplateDialog}
                onSelectDoc={(doc) => onTemplateSelect(doc)}
            />
        </div>
    );
};

const MY_STYLE = {
    doc_btn: {
        width: '150px',
        whiteSpace: 'nowrap' as 'nowrap',
    },
    doc_btn_label: {
        // width: '60px',
        // textAlign: 'left' as 'left',
        flexGrow: 1
    }
}

const ITEM_HEIGHT = 48;
import ReactGA from 'react-ga4';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function App() {
  if (process.env.REACT_APP_ENV === 'prod') {
    ReactGA.initialize([
      { trackingId: 'G-P5YE4LCNFB' },
      {
        trackingId: 'AW-11399435147',
        gtagOptions: { send_page_view: false },
      },
    ]);
  }

  useEffect( () => {
    if (process.env.REACT_APP_ENV === 'prod') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "o171my3980");`;
      // Append the script to the head
      document.head.appendChild(script);
  
      return () => {
        document.head.removeChild(script);
      };
    }
    
  }, []);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              <ProgressBarStyle />
              <ScrollToTop />
              <Router />
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}

import Echo from "laravel-echo";
import Pusher from "pusher-js";
import {OptionsObject, useSnackbar } from "notistack";
import { getTypeLabel, ResourceType, UserNotification } from "../@types/user_notification";
import useAuth from "./useAuth";

const baseUrl = process.env.REACT_APP_HOST_API_KEY?.replace(/\/api$/, '');
console.log("Base url" , baseUrl);
export const AUTH_ENDPOINT = `${baseUrl}/broadcasting/auth`;

type NotificationCallback = (notification: UserNotification) => void;
type NotificationErrorCallback = (error: any) => void;
const optionsNotificationsSnackbar: OptionsObject = {
    variant: 'success',
    preventDuplicate: true,
    autoHideDuration: 10000,
    persist: false,
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
    }
};

const useEcho = () => {
    // @ts-ignore
    window.Pusher = Pusher;

    // @ts-ignore
    // window.Pusher.logToConsole = true;

    const echo = new Echo({
        broadcaster: 'pusher',
        cluster: 'app',
        key: 'DDt3HA.BwK6wg',
        authEndpoint: AUTH_ENDPOINT,
        wsHost: 'realtime-pusher.ably.io',
        wsPort: 443,
        forceTLS: true,
        auth: {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            },
        },
        encrypted: true,
        enableStats: false,
        enabledTransports: [
            'ws',
            'wss'
        ]
    });
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuth();

    const subscribe = (onNotify: NotificationCallback, onError: NotificationErrorCallback) => {
        console.log(`user_notifications.${user?.id}`)
        echo.private(`user_notifications.${user?.id}`)
            .listen('.user_notification.created',(notification: UserNotification) => {
                console.log("notification: ", notification);
                enqueueSnackbar(`${notification.message}`, optionsNotificationsSnackbar);
                onNotify(notification);
            })
            .error((error: any) => (error));
    }

    return { echo, subscribe };
}



export default useEcho;
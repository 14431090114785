import React, { lazy } from 'react';
import { Navigate } from "react-router-dom";
import GetStartedLayout from 'src/layouts/GetStartedLayout';
import Loadable from "./loadable";

const GetStartedPage = Loadable(lazy(() => import('../pages/misc/GetStartedPage')));

const MainRoutes = [
    {
        path: '/',
        element: <GetStartedLayout />,
        children: [
            { path: 'get-started', element: <GetStartedPage /> },
        ],
    },
    {
        path: '/register',
        children: [
            { element: <Navigate to={'/auth/register'} replace />, index: true }
        ],
    },
    {
        path: '/employee',
        children: [
            { element: <Navigate to={'/auth/login'} replace />, index: true }
        ],
    },
    {
        path: '/admin',
        children: [
            { element: <Navigate to={'/auth/login'} replace />, index: true }
        ],
    },
];

export default MainRoutes;

import { useRef, useEffect, useState, useCallback } from 'react';
import axios from 'src/utils/axios';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
    Box, Grid, Typography, Stack, Button, CircularProgress, Backdrop,
    Menu, MenuItem, MenuProps
} from '@mui/material';
// components
import Iconify from "../../../../components/Iconify";
// sections
import {ConfirmSaveDocDialog, SelectTemplateDialog} from "../../../../sections/admin/custom-doc";
// PDFTron
import WebViewer, {Core, UI} from '@pdftron/webviewer';
// @types
import {CustomDoc, CustomDocUser, DocumentType} from "../../../../@types/user";
//util
import {base64ToBlob} from "../../../../utils/fileUtil";
// config
import {DocInputTypeEnum, DocumentCategoryEnum, APRYSE_LICENSE_KEY, ROLE} from "../../../../config";
// hooks
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import {Link as RouterLink, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useSnackbar} from "notistack";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 200,
        color: 'rgb(55, 65, 81)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

type DocumentCategoryType = {
    id: string;
    text_id: string;
    name: string;
    type: string;
    unique_id: string;
}

type Props = {
    doc: CustomDoc | null;
    categories: DocumentCategoryType[];
    forcedCategory: string;
    onComplete: (doc: CustomDoc | null) => void;
};

const GET_STARTED_DOC_PATH = '/pdf/get_started.pdf';

type ConfirmSaveModalState = {
    isOpen: boolean;
    resolve: (value: any) => void;
}

const initConfirmSaveModalState: ConfirmSaveModalState = {
    isOpen: false,
    resolve: () => { }
}

type AnnotationCustomProps = {
    textColor : string;
    labelText: string,
}

export const companyAnnotationProps: AnnotationCustomProps = {
    textColor: '#7DDA58',
    labelText: 'SIGNER2',
}

export const employeeAnnotationProps: AnnotationCustomProps = {
    textColor: '#3d56e5',
    labelText: 'SIGNER1',
}

const initialCategories: DocumentCategoryType[] = [];

const initialFormOptions = {
    categories: initialCategories
}

type DocFormState = {
    isOpen: boolean;
    doc: CustomDoc | null;
}

const initDocFormState: DocFormState = {
    isOpen: false,
    doc: null,
}

// ----------------------------------------------------------------------

export default function AdminPdfViewer({}) {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [docFormState, setDocFormState] = useState(initDocFormState);
    const [doc, setDoc] = useState<{
        id: number|string;
        name: string;
    }>({
        id: 0,
        name: ''
    });
    const [formOptions, setFormOptions] = useState(initialFormOptions);
    const createDocType: string = searchParams.get('doc_type') ? searchParams.get('doc_type') as string : '';
    const retUrl: string = searchParams.get('ret_url') ? searchParams.get('ret_url') as string : '';
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams();
    const [docs, setDocs] = useState([] as Array<CustomDoc>);
    const isMountedRef = useIsMountedRef();
    const [docInstance, setDocInstance] = useState<any>({});
    const [confirmSaveOpenState, setConfirmSaveState] = useState(initConfirmSaveModalState);
    const [templateDialogState, setTemplateDialogState] = useState({ isOpen: false });
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [addDocAnchorEl, setAddDocAnchorEl] = useState<HTMLElement | null | undefined>(null);
    const addDocOpen = Boolean(addDocAnchorEl);
    const forcedCategory: string = searchParams.get('doc_type') ? searchParams.get('doc_type') as string : '';
    const viewer = useRef(null);
    const filePicker = useRef(null);

    const addField = useCallback((
        type: any,
        annotationProps: AnnotationCustomProps,
        point: any = {},
        name: string = '',
        value: string = '',
        flag: any = {},
        height: any = null,
        width: any = null,
        pageNum: any = null,
        instance: any = null
    ) => {
        let documentViewer;
        let Annotations;

        // have to do this assignment workaround to avoid
        //   typescript warning about "prefer object destructuring"
        if (instance !== null) {
            const { documentViewer: dv, Annotations: an } = instance.Core;
            documentViewer = dv;
            Annotations = an;
        } else if (docInstance !== null) {
            const { documentViewer: dv, Annotations: an } = docInstance.Core;
            documentViewer = dv;
            Annotations = an;
        } else {
            console.error('docInstance is null');
        }

        const doc = documentViewer.getDocument();

        const annotationManager = documentViewer.getAnnotationManager();
        const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
        const page = displayMode.getSelectedPages(point, point);

        if (pageNum !== null) {
            page.first = pageNum;
        }

        if (!!point.x && page.first == null) {
            console.log("don't add field to an invalid page location");
            return; // don't add field to an invalid page location
        }

        const page_idx = page.first !== null ? page.first : documentViewer.getCurrentPage();
        const page_info = doc.getPageInfo(page_idx);
        const page_point = displayMode.windowToPage(point, page_idx);
        const zoom = documentViewer.getZoomLevel();

        var textAnnot = new Annotations.FreeTextAnnotation();
        textAnnot.PageNumber = page_idx;
        const rotation = documentViewer.getCompleteRotation(page_idx) * 90;
        textAnnot.Rotation = rotation;

        if (height !== null && width !== null) {
            textAnnot.X = point.x;
            textAnnot.Y = point.y
            textAnnot.Width = width;
            textAnnot.Height = height;
        } else {
            if (rotation === 270 || rotation === 90) {
                textAnnot.Width = 50.0 / zoom;
                textAnnot.Height = 250.0 / zoom;
            } else {
                textAnnot.Width = 250.0 / zoom;
                textAnnot.Height = 50.0 / zoom;
            }

            textAnnot.X = (page_point.x || page_info.width / 2) - textAnnot.Width / 2;
            textAnnot.Y = (page_point.y || page_info.height / 2) - textAnnot.Height / 2;
        }

        textAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));
        textAnnot.custom = {
            type,
            value,
            flag,
            name: `_${annotationProps.labelText}_${type}_`,
        };

        // set the type of annot
        textAnnot.setContents(textAnnot.custom.name);
        textAnnot.FontSize = '' + 20.0 / zoom + 'px';
        textAnnot.FillColor = new Annotations.Color(211, 211, 211, 0.5);
        textAnnot.TextColor = new Annotations.Color(annotationProps.textColor);
        textAnnot.StrokeThickness = 1;
        textAnnot.StrokeColor = new Annotations.Color(0, 165, 228);
        textAnnot.TextAlign = 'center';

        textAnnot.Author = annotationManager.getCurrentUser();

        annotationManager.deselectAllAnnotations();
        annotationManager.addAnnotation(textAnnot, true);
        annotationManager.redrawAnnotation(textAnnot);
        annotationManager.selectAnnotation(textAnnot);
    }, [docInstance]);

    const webViewerOptions = {
        // initialDoc: GET_STARTED_DOC_PATH,
        licenseKey: APRYSE_LICENSE_KEY,
        path: '/pdftron',
        disabledElements: [
            'ribbons',
            'toggleNotesButton',
            'searchButton',
            'menuButton',
        ],
    };

    const initPdfTron = useCallback((docBlob: any) => {
        // @ts-ignore
        WebViewer(webViewerOptions, viewer.current).then(instance => {
            setDocInstance(instance);
            // select only the view group
            instance.UI.setToolbarGroup('toolbarGroup-View');

            instance.Core.documentViewer.addEventListener('annotationsLoaded', () => {
                const annotsToDraw: any = [];
                // perform document operations
                const { Annotations, documentViewer } = instance.Core;
                const annotationManager = documentViewer.getAnnotationManager();

                annotationManager.getAnnotationsList().map(annotation => {

                    annotation.ReadOnly = true;

                    // @ts-ignore
                    Annotations.WidgetAnnotation.getCustomStyles = function (widget: any) {

                        widget.fieldFlags.set('ReadOnly', true);

                        return {
                            pointerEvents: 'none',
                            cursor: 'not-allowed',
                            border: `1px solid transparent`
                        };
                    };

                    // draw the annotation the viewer
                    annotsToDraw.push(annotation);
                });
                annotationManager.drawAnnotationsFromList(annotsToDraw);
            });

            var { Feature } = instance.UI;

            instance.UI.disableFeatures([
                Feature.NotesPanel,
                Feature.Annotations
            ]);

            instance.UI.disableElements(['toolbarGroup-View', 'menuButton', 'viewControlsButton', 'searchButton', 'panToolButton', 'leftPanelButton', 'toolbarGroup-Forms', 'selectToolButton']);

            if (docBlob) {
                instance.UI.loadDocument(docBlob, { filename: "test.pdf" });
            } else {
                instance.UI.loadDocument(GET_STARTED_DOC_PATH);
            }
            // const { iframeWindow } = instance;

            // @ts-ignore
            filePicker.current.onchange = (e: any) => {
                const file = e.target.files[0];
                if (file) {
                    instance.UI.loadDocument(file);
                }
            };
        });
        // disabling for addField() dependency
        // From https://stackoverflow.com/a/55854902
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const makeRequest = async (url: string) => {
        let _params = {};

        const response = await axios.get(url, {
            params: _params
        });

        let { data } = response.data;

        return data;
    }

    const getCustomDoc = useCallback(async (docId: number) => {

        let data = null;

        try {

            if (searchParams.get('type') === 'custom_doc') {
                data = await makeRequest(`/app/admin/custom-doc/${docId}/base64`);
            }

            if (searchParams.get('type') === 'custom_doc_user') {
                data = await makeRequest(`/app/admin/custom-doc-user/${docId}/getBase64`);
            }

            if (searchParams.get('type') === 'employee_file') {
                data = await makeRequest(`/app/admin/employee_file/${docId}/base64`);
            }

        } catch (err) {
            console.log(err);
        }

        if (data) {
            const blobData = base64ToBlob(data.pdf_base64);
            initPdfTron(blobData);
        }

    }, [initPdfTron]);

    const getCustomDocInfo = useCallback(async (docId: number) => {
        try {
            let url = `/app/admin/custom-doc-user/view/${docId}`;

            let _params = {
                type: searchParams.get('type')
            };

            const response = await axios.get(url, {
                params: _params
            });

            let { data } = response.data;
            setDoc(data);

        } catch (err) {
            console.log(err);
        }
    }, []);

    const getTemplateDownloadUrl = useCallback(async (item: DocumentType) => {
        try {
            let url = item.get_file_url;
            const response = await axios.get(url);

            if (isMountedRef.current) {
                let { url } = response.data.data;
                return url;
            }
        } catch (err) {
            console.log(err);
            return '';
        }
    }, [isMountedRef]);

    const loadTemplateDoc = useCallback(async (doc: DocumentType) => {
        try {
            setLoadingOpen(true);

            const downloadUrl = await getTemplateDownloadUrl(doc);

            const response = await axios.get(downloadUrl, { responseType: 'arraybuffer' });

            const blobData = new Blob([response.data]);

            docInstance.UI.loadDocument(blobData, { filename: "template.pdf" });

            setLoadingOpen(false);
        } catch (err) {
            console.log(err);
            setLoadingOpen(false);
        }
    }, [docInstance, getTemplateDownloadUrl]);


    const initPage = useCallback(async () => {
        if (params.document_id != undefined) {
            getCustomDoc(Number(params.document_id));
            getCustomDocInfo(Number(params.document_id));
        }
    }, []);

    useEffect(() => {
        getFormOptions();
        initPage();
    }, []);

    const closeTemplateDialog = useCallback(() => {
        setTemplateDialogState({ isOpen: false });
    }, []);

    const onTemplateSelect = useCallback(async (doc: DocumentType) => {
        closeTemplateDialog();
        loadTemplateDoc(doc);
    }, [loadTemplateDoc, closeTemplateDialog]);

    const getFormOptions = useCallback(async () => {
        try {
            let url = `/app/admin/document/form-options`;

            const response = await axios.get(url);

            if (isMountedRef.current) {
                let { data } = response.data;

                setFormOptions(data);
            }
        } catch (err) {
            console.log(err);
        }
    }, [isMountedRef]);

    return (
        <div>
            <div style={{
                display: 'flex',
                marginBottom: '10px',
                flexDirection: 'column',
                textAlign: 'center',
            }}>
                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    sx={{  alignSelf: 'flex-start', marginBottom: '10px' }}*/}
                {/*    startIcon={<Iconify icon={'akar-icons:arrow-back'} />}*/}
                {/*>*/}
                {/*    Back*/}
                {/*</Button>*/}
                <p>
                    <strong>Document</strong>
                </p>
                <p>{doc?.name}</p>
            </div>


            <div style={{
                width: '50%',
                margin: '0 auto'
            }}>
                <div className="webviewer" ref={viewer} style={{height: "90vh"}}/>
            </div>
            <input type="file" ref={filePicker} style={{display: 'none' }} />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingOpen}
            >
                <CircularProgress color="primary" />
            </Backdrop>

            <input
                type="file"
                ref={filePicker}
                accept="application/pdf"
                style={{ display: 'none' }}
            />

            <SelectTemplateDialog
                open={templateDialogState.isOpen}
                onCloseConfirm={closeTemplateDialog}
                onSelectDoc={(doc) => onTemplateSelect(doc)}
            />
        </div>
    );
};

const MY_STYLE = {
    doc_btn: {
        width: '150px',
        whiteSpace: 'nowrap' as 'nowrap',
    },
    doc_btn_label: {
        // width: '60px',
        // textAlign: 'left' as 'left',
        flexGrow: 1
    }
}

const ITEM_HEIGHT = 48;
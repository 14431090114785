import React, { lazy } from 'react';
import { Navigate } from "react-router-dom";
import Loadable from "./loadable";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import { PATH_AFTER_LOGIN, ROLE } from "../config";
import AdminDashboardLayout from "../layouts/admin/dashboard";
import AdminPdfViewer from "../pages/admin/shared/document/AdminPdfViewer";
import DocsHrPage from "../pages/admin/docs/DocsHrPage";
import DocsSafetyPage from "../pages/admin/docs/DocsSafetyPage";
import DocsPendingPage from "../pages/admin/docs/DocsPendingPage";
import DocsEmployeePage from "../pages/admin/docs/DocsEmployeePage";

const AdminDashboardPage = Loadable(lazy(() => import('../pages/admin/AdminDashboardPage')));
const AdminCustomDocFormPage = Loadable(lazy(() => import('../pages/admin/AdminCustomDocFormPage')));
const Organigram = Loadable(lazy(() => import('../sections/shared/orgchart')));
const AdminEmployeesPage = Loadable(lazy(() => import('../pages/admin/AdminEmployeesPage')));
const AdminDepartmentsPage = Loadable(lazy(() => import('../pages/admin/AdminDepartmentsPage')));
const AdminPositionsPage = Loadable(lazy(() => import('../pages/admin/AdminPositionsPage')));
const AdminImportEmployeePage = Loadable(lazy(() => import('../pages/admin/AdminImportEmployeePage')));

const AdminGoalsPage = Loadable(lazy(() => import('../pages/admin/goal/AdminGoalsPage')));
const AdminGoalPage = Loadable(lazy(() => import('../pages/admin/goal/AdminGoalPage')));
const AdminSurveysPage = Loadable(lazy(() => import('../pages/admin/survey/AdminSurveysPage')));
const AdminSurveyCreatePage = Loadable(lazy(() => import('../pages/admin/survey/AdminSurveyCreatePage')));
const AdminSurveyEditPage = Loadable(lazy(() => import('../pages/admin/survey/AdminSurveyEditPage')));
const AdminSurveyPage = Loadable(lazy(() => import('../pages/admin/survey/AdminSurveyPage')));
const AdminReviewsPage = Loadable(lazy(() => import('../pages/admin/review/AdminReviewsPage')));
const AdminReviewsDuePage = Loadable(lazy(() => import('../pages/admin/review/AdminReviewsDuePage')));
const AdminReviewCreatePage = Loadable(lazy(() => import('../pages/admin/review/AdminReviewCreatePage')));
const AdminReviewPage = Loadable(lazy(() => import('../pages/admin/review/AdminReviewPage')));
const AdminReviewEditPage = Loadable(lazy(() => import('../pages/admin/review/AdminReviewEditPage')));

const AdminCoursesPage = Loadable(lazy(() => import('../pages/admin/course/AdminCoursesPage')));
const AdminCourseDetailsPage = Loadable(lazy(() => import('../pages/admin/course/AdminCourseDetailsPage')));
const AdminModuleDetailsPage = Loadable(lazy(() => import('../pages/admin/course/AdminModuleDetailsPage')));

const AdminTrainingTrackerPage = Loadable(lazy(() => import('../pages/admin/learning/AdminTrainingTrackerPage')));
const AdminTrainingTrackerImportPage = Loadable(lazy(() => import('../pages/admin/learning/AdminTrainingTrackerImportPage')));
const AdminLearningPage = Loadable(lazy(() => import('../pages/admin/learning/AdminLearningPage')));
const AdminLearningDetailsPage = Loadable(lazy(() => import('../pages/admin/learning/AdminLearningDetailsPage')));
const AdminImportAssignmentPage = Loadable(lazy(() => import('../pages/admin/learning/AdminImportAssignmentPage')));
const AdminLearningModulePage = Loadable(lazy(() => import('../pages/admin/learning/AdminLearningModulePage')));
const AdminLearningQuizPage = Loadable(lazy(() => import('../pages/admin/learning/AdminLearningQuizPage')));
const AdminLearningQuizResultPage = Loadable(lazy(() => import('../pages/admin/learning/AdminLearningQuizResultPage')));
const AdminLibraryPage = Loadable(lazy(() => import('../pages/admin/AdminLibraryPage')));
const AdminProfilePage = Loadable(lazy(() => import('../pages/admin/AdminProfilePage')));

const AdminTimeOffPage = Loadable(lazy(() => import('../pages/admin/AdminTimeOffPage')));

const EmployeeTakeSurveyPage = Loadable(lazy(() => import('../pages/employee/EmployeeTakeSurveyPage')));
const EmployeeReviewPage = Loadable(lazy(() => import('../pages/employee/EmployeeReviewPage')));

const AdminRoutes = [
    {
        path: 'admin',
        element: (
            <RoleBasedGuard accessibleRoles={[ROLE.ADMIN]}>
                <AdminDashboardLayout />
            </RoleBasedGuard>
        ),
        children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'dashboard', element: <AdminDashboardPage /> },
            // custom documents
            { path: 'custom-document/create', element: <AdminCustomDocFormPage /> },
            { path: 'custom-document/:id/edit', element: <AdminCustomDocFormPage /> },
            // org chart
            { path: 'org-chart', element: <Organigram /> },
            // employees
            { path: 'employees', element: <AdminEmployeesPage /> },
            { path: 'departments', element: <AdminDepartmentsPage /> },
            { path: 'positions', element: <AdminPositionsPage /> },
            { path: 'employee/import', element: <AdminImportEmployeePage /> },
            // performance - goals
            { path: 'performance/goals', element: <AdminGoalsPage /> },
            { path: 'performance/goal/:id', element: <AdminGoalPage /> },
            // performance - surveys
            { path: 'performance/surveys', element: <AdminSurveysPage /> },
            { path: 'performance/survey/create', element: <AdminSurveyCreatePage /> },
            { path: 'performance/survey/:id', element: <AdminSurveyPage /> },
            { path: 'performance/survey/:id/edit', element: <AdminSurveyEditPage /> },
            { path: 'performance/survey/:id/take', element: <EmployeeTakeSurveyPage /> },
            // performance - reviews
            { path: 'performance/reviews', element: <AdminReviewsPage /> },
            { path: 'performance/review/create', element: <AdminReviewCreatePage /> },
            { path: 'performance/review/upcoming', element: <AdminReviewsDuePage /> },
            { path: 'performance/review/:id', element: <AdminReviewPage /> },
            { path: 'performance/review/:id/reviewee', element: <EmployeeReviewPage /> },
            { path: 'performance/review/:id/edit', element: <AdminReviewEditPage /> },
            // courses
            { path: 'course', element: <AdminCoursesPage /> },
            { path: 'course/:id', element: <AdminCourseDetailsPage /> },
            { path: 'course/module/:id', element: <AdminModuleDetailsPage /> },
            // learning
            { path: 'learning', element: <AdminLearningPage /> },
            { path: 'docs-hr', element: <DocsHrPage /> },
            { path: 'docs-safety', element: <DocsSafetyPage /> },
            { path: 'docs-employee', element: <DocsEmployeePage /> },
            { path: 'docs-pending', element: <DocsPendingPage /> },

            { path: 'tracker', element: <AdminTrainingTrackerPage /> },
            { path: 'tracker/import', element: <AdminTrainingTrackerImportPage /> },
            { path: 'learning/:id', element: <AdminLearningDetailsPage /> },
            { path: 'learning/:id/import', element: <AdminImportAssignmentPage /> },
            { path: 'learning/module/:id', element: <AdminLearningModulePage /> },
            { path: 'learning/module/:id/quiz', element: <AdminLearningQuizPage /> },
            { path: 'learning/module/:id/quiz/result', element: <AdminLearningQuizResultPage /> },
            { path: 'library', element: <AdminLibraryPage /> },
            { path: 'time-off', element: <AdminTimeOffPage /> },
            { path: 'profile', element: <AdminProfilePage /> },
            { path: 'pdf-viewer/:document_id', element: <AdminPdfViewer />}
        ],
    },
];

export default AdminRoutes;

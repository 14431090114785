// @mui
import { 
  Stack,
  Typography,
  Link
} from '@mui/material';
// assets
import { DocIllustration } from '../../../../assets';

import { useEffect } from "react";

import Tawkto from "src/utils/tawkto";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useAuth();

  useEffect(() => {
    const propertyId = process.env.REACT_APP_TAWKTO_PROPERTY_ID
    const widgetId=process.env.REACT_APP_TAWKTO_WIDGET_ID

    let tawk = new Tawkto(propertyId,widgetId)

    tawk.onLoad(() =>
    {
      tawk.setAttributes({
        name: `${user?.first_name} ${user?.last_name}`,
        email: user?.email,
        phone: user?.phone,
        jobtitle: user?.position,
        companyname: user?.company?.name,
      },[])
    })
  })

  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <DocIllustration sx={{ width: 1 }} />
      
      {/* <Button variant="text" color="primary">Logout</Button> */}

      <div>
        {/* <Typography gutterBottom variant="subtitle1">
        Powered by Bizhaven
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need help?
          <br /> Please check our docs
        </Typography> */}

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Powered by Bizhaven
        </Typography>

        <Link href="mailto:support@bizhaven.com">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Need help?
          </Typography>
        </Link>

      </div>

    </Stack>
  );
}

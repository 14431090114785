import { Navigate, useRoutes } from 'react-router-dom';
import AuthRoutes from './authRoutes';
import SuperRoutes from './superRoutes';
import MainRoutes from './mainRoutes';
import AdminRoutes from './adminRoutes';
import EmployeeRoutes from './employeeRoutes';
import ErrorRoutes from './errorRoutes';

export default function Router() {
  return useRoutes([
    // @todo: We should redirect for all pages if the user is not authenticated
    //   http://localhost:8083/employee/onboard is not redirected for example to /auth/login.
    {
      path: '/',
      children: [
        { element: <Navigate to={'/auth/login'} replace />, index: true }
      ],
    },

    ...MainRoutes,
    ...AuthRoutes,
    ...SuperRoutes,
    ...AdminRoutes,
    ...EmployeeRoutes,
    ...ErrorRoutes,
  ]);
}


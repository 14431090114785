import { useState, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'src/utils/axios';
// @mui
import { alpha } from '@mui/material/styles';
import { Link, Button, Typography, ListItemText, MenuItem, CircularProgress } from '@mui/material';
// components
import Scrollbar from '../../../../components/Scrollbar';
import MenuPopover from '../../../../components/MenuPopover';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

const TYPE_SAFETY = 'safety';
const TYPE_HANDBOOK = 'handbook';

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

type DocType = {
  id: string;
  type: string;
  name: string;
  description: string;
  url: string;
};

const initialDocs: DocType[] = [];

type Props = {
  type: string;
  name: string;
};

export default function FilePopover({ type, name }: Props) {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [docs, setDocs] = useState(initialDocs);
  const isMountedRef = useIsMountedRef();
  const [isLoading, setIsLoading] = useState(false);

  const getDocs = useCallback(async () => {
    try {
      setIsLoading(true);
      let url = '';

      if (type === TYPE_SAFETY) {
        url = `/app/admin/company/doc/safety-manual`;
      } else if (type === TYPE_HANDBOOK) {
        url = `/app/admin/company/doc/handbook`;
      } else {
        console.log('inavlid type');
        return;
      }

      const response = await axios.get(url);
      setIsLoading(false);

      if (isMountedRef.current) {
        let { data } = response.data;

        setDocs(data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }, [isMountedRef, type]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setDocs(initialDocs);
    getDocs();
    setOpen(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpen(null);
  };

  return (
    <>
      <Button
        variant="text"
        color={open ? 'inherit' : 'primary'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Typography component="span">{name}</Typography>
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClosePopover}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: '400px',
          height: ((docs.length > 1) ? docs.length * 80 : 80),
          borderRadius: '3px',
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <Scrollbar sx={{ height: ITEM_HEIGHT * 6 }}>

          {docs.map((doc: DocType) => (
            <Link
              key={doc.id}
              href={doc.url}
              target="_blank"
            >
              <MenuItem
                href={doc.url}
                key={1}
                onClick={() => {
                  handleClosePopover();
                }}
              >
                <ListItemText
                  primaryTypographyProps={{ 
                    color: 'primary', 
                    typography: 'subtitle3',
                    fontSize: '0.875rem',
                    mb: 0.25,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  secondaryTypographyProps={{ typography: 'caption' }}
                  primary={doc.name}
                // secondary={"Subtitle"}
                />
              </MenuItem>
            </Link>
          ))}

          {isLoading && (
            <MenuItem>
              <CircularProgress sx={{ margin: 'auto' }}/>
            </MenuItem>
          )}

          {(!isLoading && docs.length < 1) && (
            <MenuItem
              to={'/admin/profile?tab=manuals'}
              component={RouterLink}
              onClick={() => handleClosePopover() }
            >
              <ListItemText
                primaryTypographyProps={{ 
                  color: 'primary', 
                  typography: 'subtitle2',
                  mb: 0.25,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                 }}
                secondaryTypographyProps={{ typography: 'caption' }}
                primary={
                  "No manuals found"
                }
              />
            </MenuItem>
          )}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
